body { font-size: 14px; }
.table-responsive { margin-bottom: 3rem; }
table { margin-bottom: 0px !important;  }
thead, tbody { border-width: 0px !important; }
th { font-weight: normal; background-color: #f4f4f4 !important; }
th, td { border-bottom: #e4e4e4 solid 1px !important; padding: 0.5rem !important; vertical-align: bottom; }
input[type="number"] {
	min-width: 65px;
	padding-right: 5px;
}
input[type="text"] {
	min-width: 85px;
}

input[type="date"] {
	width: 115px;
	padding: 8px 2px;
	font-size: 14px;
	letter-spacing: 0px;
}
td .btn { white-space: nowrap; }
.App td .btn-primary {
	color: #fff;
	background: #2D831F;
	padding: 5px 10px;
	border-radius: 5px !important;
	font-weight: 500;
}
.App td .btn-primary:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.form-select { min-width: 75px; padding:0.375rem 2.0rem 0.375rem 0.75rem; }
.btn-group, .btn-group-vertical {
	align-items: center;
}
.table > :not(:first-child) {
  border-top: none;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
	box-shadow: none;
	background: green;
	border-color: unset;
}
.btn-check:focus + .btn-primary, .btn-primary {
	border-color: green;
	background-color: green;
}
.btn-check:focus + .btn-primary, .btn-primary:hover{
	border-color: rgb(1, 94, 1);
	background-color: rgb(1, 94, 1);
}
.wrapper {
	display: flex;
	min-height: 100vh;
	position: relative;
}
.wrapper:after {
	content: '';
	width: 100%;
	height: 100%;
	display: none;
	position: absolute;
	background-color: rgba(0,0,0,0.3);
}
.open.wrapper:after { display: block; }
.asideLeft {
	z-index: 99;
	width: 250px;
	left: -250px; 
	height: 100%; 
	position: absolute; 
	background: #2D831F;
	transition: ease-out all 0.3s;
}
.open .asideLeft { position: absolute; left: 0px; }
.asideLeft .title {
	color: #fff;
	background-color: #6DAE19;
}
.asideLeft ul { list-style-type: none; padding-left: 0; margin-bottom: 0px; }
.asideLeft li {	position: relative; }
.asideLeft .side-navigation-panel-select-option  {
	color: #fff;
    display: flex;
	font-size: 18px;
	cursor: pointer;
    align-items: center;
    padding: 15px 20px;
    transition: ease-out all 0.3s;
    border-bottom: #5A9312 solid 1px;
}
.asideLeft .side-navigation-panel-select-option i { margin-right: 10px; }
.asideLeft .side-navigation-panel-select-option:hover  { background-color: #5A9312; }
.asideLeft .side-navigation-panel-select-option-icon { margin-left: auto; }
.side-navigation-panel-select-inner-option {
    color: #fff;
    cursor: pointer;
    background: #343434;
    padding: 10px 10px 10px 55px;
    transition: ease-out all 0.3s;
    border-bottom: #565656 solid 1px;
}
.side-navigation-panel-select-inner-option:hover {background: #565656;}
.company {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #6DAE19;
}
.asideLeft .company h2 {
	color: #fff;
	margin-bottom: 0;
	padding: 10px 10px;
	text-shadow: 2px 2px 4px rgba(0,0,0,0.3); 
}
.asideRight {
	width: 100%;
	padding: 0 30px;
}
.main-header { 
	height: 80px; 
	display: flex;
	padding: 10px 15px;
	position: relative;
	align-items: center;
	margin: 0 -30px 15px;
	border-bottom: #eaeaea solid 1px; 
}
.main-header h2 { margin-bottom: 0; padding: 0 15px; }
.main-header .username .btn {
	color: #6DAE19;
	border-color: #e4e4e4;
}
.main-header .dropdown-menu { min-width: auto; }
.toggle-button.btn {
	left: 0; 
	color: #666;
	z-index: 999;
	display: block;
	background: none;
	padding: 7px 15px;
	border-color: #eaeaea;
	border-radius: 4px; 
	position: relative; 
	overflow: hidden;
	width: 56px;
	height: 40px;
	display: flex;
	transition: ease-out all 0.3s;
}
.toggle-button.btn i { transition: ease-out all 0.3s; }
.toggle-button.btn i:first-child {
	margin-right: 15px;
	margin-left: -40px;
}
.open .toggle-button.btn i:first-child {
	margin-right: 15px;
	margin-left: 0px;
}
.nav-cards h1 { 
	line-height: 0.8;
	font-weight: bold;
}
.nav-cards .card {
	border: none;
	padding: 5px 0px;
	border-radius: 10px;
	margin-bottom: 15px;
	/*box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);*/
	border: #f2f1f1 solid 1px;
	background: linear-gradient(180deg, #F6F6F6 0%, #FFFFFF 100%);
}
.nav-cards .icon {
	width: auto;
	height: 50px;
	margin-left: auto;
	filter: brightness(1) contrast(0);
}
.open .toggle-button { 
	color: #fff;
	left: 235px; 
	background: #333; 
	border-color: #333; 
	border-radius: 0 4px 4px 0; 
}

.nav-tabs { 
	display: flex;
	overflow-x: auto;
	overflow-y: clip;
	flex-flow: nowrap;
	white-space: nowrap;
	border-color: #e9e9e9;
}
.nav-tabs .nav-link {
	color: #000;
	border-width: 1px;
	border-style: solid;
	background: #f6f6f6;
	border-color: #e9e9e9;
	border-radius: 10px 10px 0 0;
}
.tab-content > .active {
    border-style: solid;
    border-color: #e9e9e9;
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 10px 10px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
}
.admin-tabs .btn { margin: 0 10px 10px 0; }
.sub-header { 
	display: flex; 
	flex-flow: wrap;
	padding: 0px 15px;
	justify-content: flex-end; 
	border-bottom: #eaeaea solid 1px; 
}
.sub-header h2 { margin-right: auto; font-size: 24px; }
.sub-header h2 span { font-size: 16px; color: #999; }
.sub-header .btn { border-radius: 4px 4px 0 0; }
.delete { color: #f00; background: transparent; border: none; }
.delete:hover { background: transparent; }
.info { 
	border: none; 
	color: #2D831F; 
	background: transparent; 
}
.info .title { color: #000; font-weight: normal; }
.info:hover { 
	background: transparent; 
}

.actions span { 
	width: 54px; 
	text-align: center; 
}
.actions span.tag { 
	width: auto; 
	font-size: 12px; 
	padding: 3px 5px; 
	font-weight: 500; 
	margin-right: 5px; 
	background: #e4e4e4; 
}
.actions .btn {
	padding: 0px;
	border: none;
	background: none;
}
.actions .btn:hover { 
	background: none; 
}
.actions .btn.view { 
	color: #2D831F; 
}
.w-54 { 
	width: 54px; 
}

.tab-pane > .add { 
	margin-top: -56px; 
	margin-bottom: 20px; 
}
.tab-pane > .add .btn { 
	border-radius: 5px 5px 0 0; 
}

.form-title { 
	color: #5A9312; 
	font-size: 16px; 
	margin-bottom: 20px; 
	padding-bottom: 10px;
	border-bottom: #5A9312 solid 1px; 
}
.form-group { 
	position: relative; 
	margin-bottom: 15px; 
}
.form-label { 
	font-size: 14px; 
}
.form-group .form-label { 
	left: 5px;
	top: -11px;
	font-size: 12px;
	padding: 0px 4px;
	background: #fff;
	margin-bottom: 0; 
	position: absolute;
}
.check-list .list-group-item {
	border: none;
	padding: 0.2rem 0;
}
.large-grid .btn { 
	font-size: 12px; 
	padding: 0px 5px; 
}
.large-grid th, .large-grid td { 
	padding: 0.25rem 0.5rem; 
}
/*.large-grid td:last-child { text-align: right; }*/
.css-42igfv {
	margin: auto;
	color: #2D831F;
}

.table-search { display: flex; margin-bottom: 1rem }
.table-search label { margin-bottom: 0; }
.pagination {
	display: flex;
	align-items: center;
}
.pagination .btn-group { margin-left: auto; }
.pagination .btn {
	color: #333;
	padding: 7px 15px;
	border-color: #e4e4e4;
	background: transparent;
}
.pagination .btn.active {
	color: #fff;
	border-color: #2D831F;
	background-color: #2D831F;
}
.data-loading {
	background-color: #E7F4E5 !important;
	position: fixed;
	left: 0;
	right: 0;
	z-index: 999;
	bottom: 0;
}
.data-loading img {
	height: 30px;
}
.procatlist th, .procatlist td {
	padding: 2px !important;
	vertical-align: bottom;
}
.procatlist {}
.table-fixed-head { width: calc(100% - 24px); }

.gridtable tbody{
  display: block;
  max-height: 195px;
  overflow-y: scroll;
}
.gridtable thead, .gridtable tbody tr{
  display: table;
  table-layout: fixed;
  width: 100%;
  border-width: 0;
}
.gridtable thead{
  width: calc(100% - 1.25em);
}
.gridtable thead{
  position: relative;
}
.gridtable thead th:last-child:after{
  	content: '';
	position: absolute;
	background-color: #f4f4f4;
	width: 1.5em;
	height: 100%;
	right: -1.25em;
	top: 0;
	border-color: #ddd;
	border-style: solid;
	border-width: 1px 1px 1px 0;
}


@media (max-width: 1200px) {
	.main-header h2 { 
		padding-left: 60px; 
	}
	.asideRight { 
		width: 100%; 
	}
}
@media (max-width: 767px) {
	.nav-cards .icon { 
		height: 30px; 
	}
	.nav-cards h5 { 
		font-size: 14px; 
		white-space: nowrap; 
	}
	.nav-cards h1 { 
		font-size: 18px; 
	}
}
@media (max-width: 600px) {
	.col { 
		flex: 0 0 100%; 
	}
	.asideRight { padding: 0 10px; }
	.main-header { margin: 0 -10px 15px; }
}