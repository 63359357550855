/*@import 'https://fonts.gstatic.com';
@import 'https://fonts.googleapis.com';*/
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Sarina&display=swap';

body { font-family: 'Roboto', sans-serif !important; }

.sarina { font-family: 'Sarina', cursive; }
.roboto { font-family: 'Roboto', sans-serif; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title {
  color: #2D831F;
  font-weight: 600;
  text-transform: uppercase;
}
/*.App .form-control, .App .form-select {
  padding: 10px 15px;
  background-color: #F6F6F6;
  border-color: #D4D4D4;
  height: 40px;
}*/
.App .form-control::placeholder { color: #999; }
.App .btn-primary {
  color: #fff;
  font-size: 14px;
  /*min-width: 150px;*/
  padding: 7px 25px;
  border-color: #2D831F;
  background-color: #2D831F;
}
.App .btn-primary:hover {
    color: #fff;
    background-color: #333;
    border-color: #333;
}
.App .btn-check:focus + .App .btn-primary, .App .btn-primary:focus {
    color: #fff;
    background-color: #333;
    border-color: #333;
    /*box-shadow: 0 0 0 .25rem rgba(51,51,51,.5);*/
}
.App a {
  color: #2D831F;
  text-decoration: none;
}

.dropzone-style {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: #ced4da;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}